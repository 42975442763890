// This file is generated by the openedx/frontend-platform's "intl-import.js" script.
//
// Refer to the i18n documents in https://docs.openedx.org/en/latest/developers/references/i18n.html to update
// the file and use the Micro-frontend i18n pattern in new repositories.
//

import messagesFromFrontendComponentFooter from './messages/frontend-component-footer';
import messagesFromFrontendComponentHeader from './messages/frontend-component-header';
import messagesFromFrontendPlatform from './messages/frontend-platform';
import messagesFromParagon from './messages/paragon';
import messagesFromFrontendAppOraGrading from './messages/frontend-app-ora-grading';

export default [
  messagesFromFrontendComponentFooter,
  messagesFromFrontendComponentHeader,
  messagesFromFrontendPlatform,
  messagesFromParagon,
  messagesFromFrontendAppOraGrading,
];
